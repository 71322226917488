import React, {useEffect} from 'react'
import {Helmet} from 'react-helmet-async';
import Sidebar from "../../components/Sidebar";

import './Legal.scss';

import ico from "../../assets/img/ico-contact.svg";

export default function PrivacyPolicy() {

    useEffect(() => {
        document.body.classList.add('logged');
        window.scrollTo(0, 0)
    });

    return (
        <main className="legal-page">

            <Helmet>
                <title>Neo Química Vista - Aviso de Privacidade</title>
            </Helmet>

            <div className="container">
                <div className="template">
                    <div className="ct-sidebar">
                        <Sidebar/>
                    </div>
                    <section className="ct-content">
                        <header className="header-inner">
                            <h1>
                                <img src={ico} alt="Aviso de Privacidade"/>
                                <span>Aviso de Privacidade</span>
                            </h1>
                        </header>

                        <div className="wrapper">
                            <article>

                                <h2>1. Objetivo</h2>

                                <p>Este aviso de privacidade (&ldquo;Aviso&rdquo;) foi desenvolvido de acordo com a Lei
                                    Geral de Prote&ccedil;&atilde;o de Dados (Lei Federal
                                    n&ordm; 13.709/2018 &ndash; &ldquo;LGPD&rdquo;) e tem como objetivo apresentar
                                    informa&ccedil;&otilde;es sobre o tratamento de dados pessoais no &acirc;mbito
                                    da&nbsp;Plataforma&nbsp;Vista Neo
                                    Qu&iacute;mica&nbsp;(&ldquo;Plataforma&rdquo; ou &ldquo;Vista&nbsp;Neo
                                    Qu&iacute;mica&rdquo;).</p>

                                <p>A Plataforma &eacute; desenvolvida e gerenciada pela&nbsp;Hypera S.A., pessoa
                                    jur&iacute;dica inscrita no CNPJ/MF sob o n&ordm; 02.932.074/0006-04, com sede na
                                    Avenida Magalh&atilde;es de Castro, n&ordm; 4.800, 24&ordm; andar, cj. 241, Cidade
                                    Jardim, CEP: 05502-001 (&ldquo;Hypera&rdquo;).</p>

                                <p>Em caso de d&uacute;vidas relacionadas aos termos desse aviso, entre em contato por
                                    meio do canal:&nbsp;vistaneoquimica@hypera.com.br.</p>

                                <h2>2. Dados pessoais coletados na Plataforma</h2>

                                <p>Para viabilizar a adequada utiliza&ccedil;&atilde;o da Plataforma, algumas
                                    informa&ccedil;&otilde;es de distribuidores parceiros e de seus respectivos
                                    representantes podem ser necess&aacute;rias. Apresentamos abaixo os dados tratados
                                    para uso da Plataforma:&nbsp;</p>

                                <table>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <p>Dados de Perfil</p>
                                        </td>
                                        <td>
                                            <p>Dados necess&aacute;rios &agrave; cria&ccedil;&atilde;o de perfil de
                                                Usu&aacute;rio na Plataforma: nome completo, endere&ccedil;o de
                                                e-mail, empresa, cargo, estado, regional, foto de perfil e senha de
                                                acesso.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Dados de Performance</p>
                                        </td>
                                        <td>
                                            <p>Informa&ccedil;&otilde;es sobre a performance do distribuidor em
                                                programas e campanhas de relacionamento da Hypera, tais como metas
                                                individuais, indicadores de desempenho e
                                                pontua&ccedil;&otilde;es.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Dados de uso da Plataforma</p>
                                        </td>
                                        <td>
                                            <p>Endere&ccedil;o IP, ID de dispositivos m&oacute;veis, data e hora de
                                                acesso &agrave; Plataforma.</p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                <h2>3. Fonte dos dados pessoais</h2>

                                <p>Os dados pessoais de distribuidores parceiros e de seus respectivos representantes
                                    cadastrados na Plataforma s&atilde;o coletados a partir de cadastros realizados em
                                    programas de relacionamento da Hypera, ou seja,&nbsp;n&atilde;o
                                    h&aacute; necessidade de novo cadastro para acessar a Plataforma.&nbsp;</p>

                                <p>Caso n&atilde;o haja interesse em utilizar as funcionalidades disponibilizadas na
                                    Plataforma, como&nbsp;dashboards&nbsp;para visualiza&ccedil;&atilde;o integrada de
                                    desempenho em programas de relacionamento da Hypera, envie uma mensagem
                                    para&nbsp;vistaneoquimica@hypera.com.br.&nbsp;</p>

                                <h2>4. Finalidades para utiliza&ccedil;&atilde;o dos dados pessoais</h2>

                                <p>Os dados pessoais coletados est&atilde;o essencialmente relacionados ao funcionamento
                                    da Plataforma e ser&atilde;o utilizados para finalidades espec&iacute;ficas,
                                    conforme a seguir:</p>

                                <ul>
                                    <li>
                                        <p>Permitir ao Usu&aacute;rio a an&aacute;lise centralizada da sua performance
                                            em programas e campanhas de relacionamento da Hypera;</p>
                                    </li>
                                    <li>
                                        <p>Identificar e autenticar os Usu&aacute;rios adequadamente, viabilizando o
                                            acesso aos recursos e funcionalidades da Plataforma e o atendimento de
                                            solicita&ccedil;&otilde;es e d&uacute;vidas;</p>
                                    </li>
                                    <li>
                                        <p>Atender solicita&ccedil;&otilde;es, chamados e d&uacute;vidas dos
                                            Usu&aacute;rios;</p>
                                    </li>
                                    <li>
                                        <p>Prevenir a ocorr&ecirc;ncia de fraudes e/ou demais atitudes
                                            il&iacute;citas;</p>
                                    </li>
                                    <li>
                                        <p>Cumprir com obriga&ccedil;&otilde;es legais ou regulat&oacute;rias e/ou
                                            exercer direitos em demandas judiciais, administrativas ou arbitrais.</p>
                                    </li>
                                </ul>

                                <p>Para al&eacute;m dessas situa&ccedil;&otilde;es, dados pessoais poder&atilde;o ser
                                    utilizados de forma anonimizada (ou seja, de maneira que n&atilde;o
                                    leve &agrave; sua identifica&ccedil;&atilde;o), com a finalidade de produzir
                                    relat&oacute;rios estat&iacute;sticos sobre o funcionamento da Plataforma e o
                                    desempenho dos programas de relacionamento da Hypera.</p>

                                <h2>5. Compartilhamento de dados pessoais</h2>

                                <p>Para atender as finalidades acima, os dados pessoais utilizados na Plataforma podem
                                    ser compartilhados com os seguintes destinat&aacute;rios, observando as
                                    justificativas legais e os limites aplic&aacute;veis:</p>

                                <ul>
                                    <li>
                                        <p>Fornecedores e prestadores de servi&ccedil;os que auxiliam na gest&atilde;o e
                                            funcionamento da Plataforma;</p>
                                    </li>
                                    <li>
                                        <p>Demais empresas do grupo econ&ocirc;mico da Hypera S.A., respeitados os
                                            prop&oacute;sitos apresentados nesse aviso; e</p>
                                    </li>
                                    <li>
                                        <p>Autoridades competentes para cumprimento de obriga&ccedil;&otilde;es legais,
                                            regulat&oacute;rias ou para atendimento de requisi&ccedil;&otilde;es
                                            oficiais.</p>
                                    </li>
                                </ul>

                                <h2>6. Seguran&ccedil;a e armazenamento dos dados pessoais</h2>

                                <p>Os dados pessoais utilizados na Plataforma ser&atilde;o mantidos, de forma segura e
                                    confidencial, pelo per&iacute;odo necess&aacute;rio para execu&ccedil;&atilde;o das
                                    finalidades descritas nesse aviso de privacidade, observados, inclusive, os prazos
                                    legais e regulat&oacute;rios de guarda das informa&ccedil;&otilde;es e a eventual
                                    necessidade de utiliza&ccedil;&atilde;o em processos judiciais, administrativos ou
                                    arbitrais.</p>

                                <h2>7. Direitos relacionados ao uso de dados pessoais&nbsp;</h2>

                                <p>A Lei Geral de Prote&ccedil;&atilde;o de Dados prev&ecirc; direitos
                                    relativos &agrave; utiliza&ccedil;&atilde;o de dados pessoais.&nbsp;</p>

                                <p>S&atilde;o direitos relacionados ao uso de dados pessoais:</p>

                                <table>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <p>Confirma&ccedil;&atilde;o e acesso aos dados</p>
                                        </td>
                                        <td>
                                            <p>O usu&aacute;rio pode solicitar informa&ccedil;&otilde;es sobre os
                                                dados tratados no &acirc;mbito da Plataforma e sobre os terceiros
                                                que acessam tais dados, bem como solicitar &agrave; Hypera o acesso
                                                aos seus dados pessoais utilizados.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Corre&ccedil;&atilde;o ou atualiza&ccedil;&atilde;o</p>
                                        </td>
                                        <td>
                                            <p>O usu&aacute;rio pode solicitar a corre&ccedil;&atilde;o de
                                                informa&ccedil;&otilde;es que estejam desatualizadas, incorretas ou
                                                incompletas.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Elimina&ccedil;&atilde;o, anonimiza&ccedil;&atilde;o ou bloqueio</p>
                                        </td>
                                        <td>
                                            <p>O usu&aacute;rio pode solicitar a exclus&atilde;o,
                                                anonimiza&ccedil;&atilde;o ou bloqueio de dados pessoais que
                                                n&atilde;o sejam mais necess&aacute;rios para o funcionamento da
                                                Plataforma.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Revoga&ccedil;&atilde;o do consentimento</p>
                                        </td>
                                        <td>
                                            <p>Nos casos em que o tratamento ocorrer mediante a
                                                obten&ccedil;&atilde;o do consentimento (autoriza&ccedil;&atilde;o),
                                                o usu&aacute;rio pode solicitar revoga&ccedil;&atilde;o dessa
                                                autoriza&ccedil;&atilde;o e/ou a elimina&ccedil;&atilde;o de dados
                                                pessoais tratados com base nessa autoriza&ccedil;&atilde;o.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Portabilidade</p>
                                        </td>
                                        <td>
                                            <p>Uma vez regulamentado pela Autoridade Nacional de
                                                Prote&ccedil;&atilde;o de Dados, o usu&aacute;rio
                                                poder&aacute; solicitar a portabilidade de seus dados pessoais.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Oposi&ccedil;&atilde;o</p>
                                        </td>
                                        <td>
                                            <p>O usu&aacute;rio pode se opor &agrave; utiliza&ccedil;&atilde;o de
                                                seus dados pessoais, desde que verificado inconformidade com a LGPD
                                                e observados os limites e imposi&ccedil;&otilde;es legalmente
                                                previstos.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Informa&ccedil;&otilde;es sobre o compartilhamento</p>
                                        </td>
                                        <td>
                                            <p>Sem preju&iacute;zo das informa&ccedil;&otilde;es fornecidas neste
                                                aviso, o usu&aacute;rio pode solicitar esclarecimentos adicionais
                                                sobre o compartilhamento de seus dados pessoais.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Revis&atilde;o de decis&otilde;es automatizadas</p>
                                        </td>
                                        <td>
                                            <p>O usu&aacute;rio pode solicitar a revis&atilde;o de decis&otilde;es
                                                aut&ocirc;nomas (sem participa&ccedil;&atilde;o humana) e /ou o
                                                fornecimento de crit&eacute;rios/procedimentos adotados para a
                                                tomada dessas decis&otilde;es.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Solicita&ccedil;&atilde;o junto &agrave; autoridade competente</p>
                                        </td>
                                        <td>
                                            <p>Caso n&atilde;o seja poss&iacute;vel exercer os direitos apresentados
                                                nesse t&oacute;pico junto &agrave; Hypera, o usu&aacute;rio
                                                poder&aacute; encaminhar
                                                solicita&ccedil;&atilde;o &agrave; Autoridade Nacional de
                                                Prote&ccedil;&atilde;o de Dados (ANPD).</p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                <p>O exerc&iacute;cio dos direitos relacionados no quadro acima depende de pr&eacute;via
                                    solicita&ccedil;&atilde;o do usu&aacute;rio e da inexist&ecirc;ncia de qualquer
                                    restri&ccedil;&atilde;o t&eacute;cnica e/ou jur&iacute;dica. &nbsp;</p>

                                <p>Caso queira solicitar o exerc&iacute;cio dos direitos mencionados acima, entre em
                                    contato por meio do canal:&nbsp;<a
                                        href="mailto:dpo@hypera.com.br">dpo@hypera.com.br</a>.&nbsp;</p>

                                <h2>8. Atualiza&ccedil;&otilde;es</h2>

                                <p>Este Aviso poder&aacute; sofrer atualiza&ccedil;&otilde;es e ajustes a qualquer
                                    tempo. Caso haja atualiza&ccedil;&atilde;o relevante, utilizaremos os meios
                                    dispon&iacute;veis para informar os Usu&aacute;rios da Plataforma.</p>

                                <p>Data da &uacute;ltima atualiza&ccedil;&atilde;o: 03/04/2023</p>

                            </article>
                        </div>

                    </section>
                </div>
            </div>
        </main>
    )
}