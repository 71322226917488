import React, {useState} from "react";
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {NavLink} from "react-router-dom";
import * as Recaptcha from "recaptcha-v3";

import api from "../../utils/api";
import Loading from "../Loading";

import './Form.scss';

function FormForgotPassword() {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const currentUrl = window?.location?.origin;

    return (
        <div className="login">
            <h3>Esqueci a senha</h3>

            <p>Digite o endereço de e-mail usado quando se inscreveu e uma nova senha será enviada para este
                endereço.</p>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    email: sessionStorage.getItem("@vista-nq-email") ? sessionStorage.getItem("@vista-nq-email") : ""
                }}
                validationSchema={
                    Yup.object().shape({
                        email: Yup.string().email('* Formato de e-mail inválido.')
                            .required('* Campo obrigatório.')
                    })
                }
                onSubmit={async (values, actions) => {
                    setLoading(true);
                    Recaptcha.load(process.env.REACT_APP_RECAPTCHA_KEY).then(async (recaptcha) => {
                        recaptcha.execute('USER_FORGOT').then(async (token) => {

                            const headers = {'Recaptcha': token}

                            const payload = {
                                "emailTo": values.email,
                                "appUrl": currentUrl
                            };

                            try {
                                const response = await api.post("/users/forgot-password", payload, {headers: headers});
                                setMessage(`${response.data?.data?.message}`);
                            } catch (err) {
                                const errorMessage = (err.response && err.response.data ? err.response.data.error : "Erro ao solicitar recuperação de senha.");
                                actions.setErrors({email: errorMessage});
                            } finally {
                                setLoading(false);
                            }

                        }).catch((err) => {
                            toast.error('Erro ao processar ReCaptcha. Tente novamente.');
                            setLoading(false);
                        });
                    }).catch((err) => {
                        toast.error('Erro ao processar ReCaptcha. Tente novamente.');
                        setLoading(false);
                    });
                }}>
                {({errors, touched, props}) => (
                    <Form className="form-default form-users">

                        <Loading showLoading={loading}/>

                        <div className={`field ${errors.email && touched.email ? 'error' : null}`}>
                            <label htmlFor="email">E-mail</label>
                            <Field
                                name="email"
                                type="email"
                            />
                            <ErrorMessage name="email" component="span"/>
                        </div>

                        <p>{message}</p>

                        <button
                            type="submit"
                            className={`btn-default btn-submit ${loading ? "loading" : ""}`}
                            disabled={`${loading ? "disabled" : ""}`}>
                            Recuperar senha
                        </button>

                        <p className="link">
                            <NavLink to="/">Voltar</NavLink>
                        </p>

                    </Form>
                )}
            </Formik>

        </div>
    );
}

export default FormForgotPassword;