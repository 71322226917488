import jwt_decode from "jwt-decode";

export const TOKEN_KEY = "@vista-nq";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
}
export const getDecodedToken = () => {
  const TOKEN = getToken();
  const DECODED_TOKEN = TOKEN ? jwt_decode(TOKEN) : logout();
  // console.log("DECODED_TOKEN", DECODED_TOKEN);
  return DECODED_TOKEN;
}

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};