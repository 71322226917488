import React from 'react';

import './Loading.scss';

const LoadingIco = ({showLoading, color}) => {
  return (
    <div className={`ico-loading ${showLoading ? "show" : ""}`}>
      <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
           preserveAspectRatio="xMidYMid" className="lds-ring" style={{"background": "0 0"}}>
        <circle cx="50" cy="50" fill="none" r="40" stroke={color ? color : "#FFCD00"} strokeWidth="10"/>
        <circle cx="50" cy="50" fill="none" r="40" stroke="#ffffff" strokeWidth="6" strokeLinecap="square"
                transform="rotate(636.923 50 50)">
          <animateTransform attributeName="transform" type="rotate" calcMode="linear"
                            values="0 50 50;180 50 50;720 50 50" keyTimes="0;0.5;1" dur="1.3s" begin="0s"
                            repeatCount="indefinite"/>
          <animate attributeName="stroke-dasharray" calcMode="linear"
                   values="25.132741228718345 226.1946710584651;100.53096491487338 150.79644737231007;25.132741228718345 226.1946710584651"
                   keyTimes="0;0.5;1" dur="1.3" begin="0s" repeatCount="indefinite"/>
        </circle>
      </svg>
    </div>
  )
}

export default LoadingIco;