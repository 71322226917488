import React, {useEffect} from 'react'
import {Helmet} from 'react-helmet-async';
import Sidebar from "../../components/Sidebar";

import './Legal.scss';

import ico from "../../assets/img/ico-contact.svg";
import {Link} from "react-router-dom";

export default function Terms() {

    useEffect(() => {
        document.body.classList.add('logged');
        window.scrollTo(0, 0)
    });

    return (
        <main className="legal-page">

            <Helmet>
                <title>Neo Química Vista - Termos de Uso</title>
            </Helmet>

            <div className="container">
                <div className="template">
                    <div className="ct-sidebar">
                        <Sidebar/>
                    </div>
                    <section className="ct-content">
                        <header className="header-inner">
                            <h1>
                                <img src={ico} alt="Termos de Uso"/>
                                <span>Termos de Uso</span>
                            </h1>
                        </header>

                        <div className="wrapper">
                            <article>

                                <h2>1. Apresenta&ccedil;&atilde;o</h2>

                                <p>A Vista Neo Qu&iacute;mica &eacute; uma plataforma desenvolvida e gerenciada pela
                                    Hypera S.A. (&ldquo;Hypera&rdquo;), pessoa jur&iacute;dica inscrita no CNPJ/MF sob o
                                    n&ordm; 02.932.074/0006-04, com sede na Avenida Magalh&atilde;es de Castro,
                                    n&ordm; 4.800, 24&ordm; andar, cj. 241, Cidade Jardim, CEP: 05502-001, que tem como
                                    funcionalidade permitir aos Usu&aacute;rios a an&aacute;lise centralizada de sua
                                    performance em campanhas e programas de relacionamento da Hypera.</p>

                                <p>Estes Termos de Uso (&ldquo;Termos&rdquo;) determinam as regras e
                                    condi&ccedil;&otilde;es de acesso e utiliza&ccedil;&atilde;o da Plataforma Vista Neo
                                    Qu&iacute;mica (&ldquo;Plataforma&rdquo;) por parte dos Usu&aacute;rios. A
                                    concord&acirc;ncia com estes Termos &eacute; requisito para a
                                    utiliza&ccedil;&atilde;o da Plataforma, por isso recomendamos que leia o documento
                                    atentamente.&nbsp;</p>

                                <h2>2. Acesso e Condi&ccedil;&otilde;es de Utiliza&ccedil;&atilde;o</h2>

                                <p>O acesso &agrave; Plataforma pode ser feito atrav&eacute;s do endereço
                                    eletr&ocirc;nico <a href="https://www.vistaneoquimica.com.br"
                                                        target="_blank">www.vistaneoquimica.com.br</a>.</p>

                                <p>Para utiliza&ccedil;&atilde;o da Plataforma, &eacute; necess&aacute;rio que o
                                    Usu&aacute;rio seja&nbsp;maior de 18 (dezoito) anos&nbsp;e seja&nbsp;representante
                                    de empresa distribuidora participante de campanhas e programas de relacionamento da
                                    Hypera.&nbsp;</p>

                                <p>A cria&ccedil;&atilde;o de perfil de acesso &agrave; Plataforma pode ser realizada a
                                    partir do fornecimento de informa&ccedil;&otilde;es, como nome completo, e-mail,
                                    empresa, cargo, estado e regional, ou a partir da integra&ccedil;&atilde;o com
                                    cadastros pr&eacute;-existentes em campanhas e programas da Hypera.&nbsp;</p>

                                <table>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <p>Caso queira saber mais sobre o tratamento de dados pessoais
                                                no &acirc;mbito da Plataforma, consulte nosso <Link
                                                    to="/aviso-de-privacidade">Aviso de Privacidade</Link>.
                                            </p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                <p>A Plataforma &eacute; disponibilizada aos Usu&aacute;rios por tempo indeterminado,
                                    por liberalidade da Hypera, a qual se reserva ao direito de, a qualquer tempo,
                                    descontinuar o funcionamento da Plataforma, sem a necessidade de aviso pr&eacute;vio
                                    e sem que tal fato enseje indeniza&ccedil;&otilde;es, compensa&ccedil;&otilde;es
                                    ou &ocirc;nus de qualquer natureza.</p>

                                <p>O Usu&aacute;rio poder&aacute;, a qualquer tempo, excluir o aplicativo de seus
                                    dispositivos &ndash; o que suspende, de forma tempor&aacute;ria, a
                                    utiliza&ccedil;&atilde;o da Plataforma, mas n&atilde;o implica na exclus&atilde;o do
                                    perfil de acesso anteriormente criado.&nbsp;</p>

                                <p>A Hypera poder&aacute;, de acordo com seus crit&eacute;rios, revogar o acesso de
                                    qualquer Usu&aacute;rio caso entenda pertinente, inclusive em decorr&ecirc;ncia da
                                    suspeita de uso irregular, il&iacute;cito ou que viole as finalidades e
                                    condi&ccedil;&otilde;es expostas nestes Termos, sem que seja devida qualquer
                                    indeniza&ccedil;&atilde;o ou compensa&ccedil;&atilde;o em raz&atilde;o disso.</p>

                                <h2>3. Deveres e Responsabilidades dos Usu&aacute;rios</h2>

                                <p>Para o bom funcionamento da Plataforma, os Usu&aacute;rios se declaram cientes e
                                    concordam com as condi&ccedil;&otilde;es apresentadas a seguir:&nbsp;</p>

                                <div align="left">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <p>S&atilde;o deveres dos Usu&aacute;rios:</p>
                                            </td>
                                            <td>
                                                <p>&Eacute; vedados aos Usu&aacute;rios:</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <ul>
                                                    <li>
                                                        <p>Garantir a veracidade das informa&ccedil;&otilde;es
                                                            fornecidas quando do cadastro e utiliza&ccedil;&atilde;o do
                                                            perfil de acesso;</p>
                                                    </li>
                                                    <li>
                                                        <p>Manter suas informa&ccedil;&otilde;es de acesso
                                                            atualizadas;</p>
                                                    </li>
                                                    <li>
                                                        <p>Manter a guarda e integridade das credenciais de acesso de
                                                            modo seguro e livre de acesso por terceiros;</p>
                                                    </li>
                                                    <li>
                                                        <p>Realizar a imediata altera&ccedil;&atilde;o de suas
                                                            credenciais e informar a Hypera atrav&eacute;s dos meios de
                                                            contato dispon&iacute;veis nestes Termos sobre eventuais
                                                            suspeitas ou confirma&ccedil;&atilde;o de comprometimento de
                                                            seu login/senha;</p>
                                                    </li>
                                                    <li>
                                                        <p>Comunicar &agrave; Hypera Pharma em caso de qualquer suspeita
                                                            de pr&aacute;tica das condutas n&atilde;o permitidas
                                                            listadas nestes Termos.</p>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>
                                                        <p>Utilizar a Plataforma para finalidades diversas das quais se
                                                            destina;</p>
                                                    </li>
                                                    <li>
                                                        <p>Compartilhar, circular ou transferir as credenciais de acesso
                                                            individuais;</p>
                                                    </li>
                                                    <li>
                                                        <p>Extrair listas ou bases de dados n&atilde;o autorizados;</p>
                                                    </li>
                                                    <li>
                                                        <p>Adulterar ou utilizar sem autoriza&ccedil;&atilde;o as
                                                            funcionalidades dispon&iacute;veis;</p>
                                                    </li>
                                                    <li>
                                                        <p>Utilizar dispositivo, software ou qualquer recurso que venha
                                                            a interferir no funcionamento operacional da Plataforma,
                                                            seus servidores e/ou redes;</p>
                                                    </li>
                                                    <li>
                                                        <p>Copiar, replicar, reproduzir ou comercializar qualquer
                                                            conte&uacute;do contido na Plataforma, inclusive
                                                            c&oacute;digos-fonte, sem pr&eacute;via e expressa
                                                            autoriza&ccedil;&atilde;o para tanto.</p>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <h2>4. Propriedade Intelectual</h2>

                                <p>O nome empresarial, a marca, o dom&iacute;nio, o c&oacute;digo-fonte, bem como o
                                    conte&uacute;do acessado atrav&eacute;s da Plataforma, s&atilde;o de propriedade
                                    exclusiva da Hypera e/ou de terceiros que tenham cedido ou
                                    licenciado &agrave; Hypera seus respectivos materiais para utiliza&ccedil;&atilde;o.
                                    O uso da Plataforma n&atilde;o importa em autoriza&ccedil;&atilde;o para que o
                                    Usu&aacute;rio possa citar e/ou utilizar marcas, nomes comerciais e logotipos de
                                    propriedade ou sob cust&oacute;dia da Plataforma para interesses pr&oacute;prios
                                    e/ou para prop&oacute;sitos diversos &agrave; utiliza&ccedil;&atilde;o das
                                    funcionalidades dispon&iacute;veis na Plataforma.</p>

                                <p>O uso da Plataforma deve respeitar todos os conte&uacute;dos protegidos pelas normas
                                    de propriedade intelectual, sendo vedada sua c&oacute;pia, reprodu&ccedil;&atilde;o
                                    ou qualquer outro tipo de utiliza&ccedil;&atilde;o que extrapole os limites
                                    indicados nestes Termos, ficando os infratores
                                    sujeitos &agrave; revoga&ccedil;&atilde;o do acesso &agrave; Plataforma e &agrave;s
                                    san&ccedil;&otilde;es legais correspondentes.</p>

                                <h2>5. Seguran&ccedil;a e Disponibilidade</h2>

                                <p>A Hypera adota medidas para proteger seus sistemas, garantir o funcionamento adequado
                                    da Plataforma e evitar situa&ccedil;&otilde;es irregulares, sejam elas acidentais ou
                                    il&iacute;citas. Ainda assim, pelas pr&oacute;prias caracter&iacute;sticas do
                                    formato digital, n&atilde;o &eacute; poss&iacute;vel assegurar que a Plataforma
                                    estar&aacute; totalmente livre de acessos n&atilde;o autorizados por terceiros de
                                    m&aacute;-f&eacute; e/ou isenta de interrup&ccedil;&otilde;es e descontinuidade do
                                    servi&ccedil;o.</p>

                                <p>Por essa raz&atilde;o, a disponibilidade da Plataforma pode ser suspensa, a
                                    crit&eacute;rio da Hypera, por per&iacute;odo indeterminado e sem a necessidade de
                                    comunica&ccedil;&atilde;o pr&eacute;via aos Usu&aacute;rios.</p>

                                <p>Ainda, em raz&atilde;o da constante necessidade de monitoramento e aprimoramento da
                                    Plataforma, a Hypera reserva-se o direito de realizar modifica&ccedil;&otilde;es e
                                    reajustar par&acirc;metros e configura&ccedil;&otilde;es independentemente da
                                    ci&ecirc;ncia e/ou autoriza&ccedil;&atilde;o pr&eacute;via dos Usu&aacute;rios.</p>

                                <h2>6. Privacidade e prote&ccedil;&atilde;o de dados&nbsp;</h2>

                                <p>Em aten&ccedil;&atilde;o &agrave;s legisla&ccedil;&otilde;es aplic&aacute;veis sobre
                                    privacidade e prote&ccedil;&atilde;o de dados, a Hypera disponibiliza <a
                                        href="/aviso-de-privacidade">aviso de privacidade</a>, que esclarece como
                                    s&atilde;o utilizadas as informa&ccedil;&otilde;es pessoais coletadas/acessadas para
                                    correto funcionamento da Plataforma.&nbsp;</p>

                                <h2>7. Canal de Contato</h2>

                                <p>Em caso de d&uacute;vidas relacionadas ao uso da Plataforma ou a estes Termos, entre
                                    em contato conosco por meio da Central de Atendimento
                                    -&nbsp;vistaneoquimica@hypera.com.br.</p>

                                <h2>8. Atualiza&ccedil;&otilde;es</h2>

                                <p>Os presentes Termos, bem como o conte&uacute;do da Plataforma poder&atilde;o sofrer
                                    atualiza&ccedil;&otilde;es e ajustes a qualquer momento, a crit&eacute;rio exclusivo
                                    da Hypera. Caso haja altera&ccedil;&atilde;o relevante, utilizaremos os meios
                                    dispon&iacute;veis para informar os Usu&aacute;rios da Plataforma.</p>

                                <p>Data da &uacute;ltima atualiza&ccedil;&atilde;o:&nbsp;03/04/2023</p>


                            </article>
                        </div>

                    </section>
                </div>
            </div>
        </main>
    )
}