import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet-async';

import Sidebar from "../../components/Sidebar";
import FormProfile from "../../components/Form/FormProfile";
import FormPassword from "../../components/Form/FormPassword";

import ico from "../../assets/img/ico-profile.svg";

export default function Profile() {

  useEffect(() => {
    document.body.classList.add('logged');
    window.scrollTo(0, 0)
  });

  return (
    <main className="profile">

      <Helmet>
        <title>Neo Química Vista - Profile</title>
      </Helmet>

      <div className="container">
        <div className="template">
          <div className="ct-sidebar">
            <Sidebar />
          </div>
          <section className="ct-content">
            <header className="header-inner">
              <h1>
                <img src={ico} alt="Meu perfil"/>
                <span>Meu Perfil</span>
              </h1>
            </header>

            <div className="wrapper">
              <h2>Dados</h2>
              <FormProfile />

              <br/>
              <h2>Senha</h2>
              <FormPassword />
            </div>

          </section>
        </div>
      </div>
    </main>
  )
}