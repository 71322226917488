import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useParams} from "react-router-dom";
import {models} from "powerbi-client";
import {PowerBIEmbed} from "powerbi-client-react";
import {toast} from "react-toastify";

import {getDecodedToken} from "../../utils/auth";
import api from "../../utils/api";

import Sidebar from "../../components/Sidebar";
import LoadingIco from "../../components/Loading/LoadingIco";

export default function Report() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reportId, setReportId] = useState("");
    const [token, setToken] = useState("");
    const [tokenType, setTokenType] = useState("");
    const [embedUrl, setEmbedUrl] = useState("");

    const DECODED_TOKEN = getDecodedToken();
    let {dataset_id} = useParams();

    useEffect(() => {
        document.body.classList.add('logged');
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getData();
    }, [dataset_id]);

    async function getData() {
        setLoading(true);

        const payload = {
            user_id: DECODED_TOKEN.data.id
        }

        try {
            const response = await api.post(`/reports/list-by-user-with-card`, payload);
            const filteredId = response?.data?.find(obj => obj.report_id === dataset_id);

            if (filteredId) {
                const hasRoles = filteredId.roles;
                hasRoles ? await getEmbed(filteredId) : await getAad();
            } else {
                toast.error("Erro ao obter informações do relatório.");
            }

        } catch (err) {
            toast.error("Erro ao obter as informações.");
        }
    }

    const getEmbed = async (filteredId) => {
        const payload = {
            report_id: dataset_id,
            group_id: filteredId.group_id,
            dataset: filteredId.dataset_id,
            username: DECODED_TOKEN.data.email,
            roles: filteredId.roles ? filteredId.roles : null,
        }

        try {
            const response = await api.post(`/powerbi/reports/embed`, payload);
            setReportId(response?.data?.reportId);
            setEmbedUrl(response?.data?.embedUrl);
            setToken(response?.data?.embedToken);
            setTokenType("embed");
            setLoading(false);
        } catch (err) {
            toast.error("Erro ao obter as informações.");
        }
    }

    const getAad = async () => {
        const payload = {
            report_id: dataset_id
        }

        try {
            const response = await api.post(`/powerbi/reports/aad`, payload);
            setReportId(response?.data?.reportId);
            setEmbedUrl(response?.data?.embedUrl);
            setToken(response?.data?.accessToken);
            setTokenType("");
            setLoading(false);
        } catch (err) {
            toast.error("Erro ao obter as informações.");
        }
    }

    if (loading) {
        return (
            <main className="dashboard">

                <Helmet>
                    <title>Neo Química Vista - Relatório</title>
                </Helmet>

                <div id="template-dashboard" className="container">
                    <div className="template">
                        <div className="ct-sidebar">
                            <Sidebar reports/>
                        </div>
                        <section className="ct-content">
                            <div className="wrapper iframe loading">
                                <LoadingIco/>
                            </div>
                        </section>
                    </div>
                </div>
            </main>
        )

    } else {
        return (
            <main className="dashboard">

                <Helmet>
                    <title>Neo Química Vista - Relatório</title>
                </Helmet>

                <div id="template-dashboard" className="container">
                    <div className="template">
                        <div className="ct-sidebar">
                            <Sidebar reports loadMinimized/>
                        </div>
                        <section className="ct-content">
                            <div className="wrapper iframe">
                                <PowerBIEmbed
                                    embedConfig={{
                                        type: 'report',
                                        id: reportId,
                                        embedUrl: embedUrl,
                                        accessToken: token,
                                        tokenType: (tokenType === "embed") ? models.TokenType.Embed : models.TokenType.Aad,
                                        settings: {
                                            panes: {
                                                filters: {
                                                    visible: false
                                                },
                                                pageNavigation: {
                                                    visible: false
                                                }
                                            }
                                        }
                                    }}
                                    cssClassName={"report"}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="ico-rotate">
                        <svg width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.323 8.616l-4.94-4.94a1.251 1.251 0 0 0-1.767 0l-10.94 10.94a1.251 1.251 0 0 0 0 1.768l4.94 4.94a1.25 1.25 0 0 0 1.768 0l10.94-10.94a1.251 1.251 0 0 0 0-1.768zM14 5.707L19.293 11 11.5 18.793 6.207 13.5zm-4.323 14.91a.25.25 0 0 1-.354 0l-1.47-1.47.5-.5-2-2-.5.5-1.47-1.47a.25.25 0 0 1 0-.354L5.5 14.207l5.293 5.293zm10.94-10.94l-.617.616L14.707 5l.616-.616a.25.25 0 0 1 .354 0l4.94 4.94a.25.25 0 0 1 0 .353zm1.394 6.265V18a3.003 3.003 0 0 1-3 3h-3.292l1.635 1.634-.707.707-2.848-2.847 2.848-2.848.707.707L15.707 20h3.304a2.002 2.002 0 0 0 2-2v-2.058zM4 9H3V7a3.003 3.003 0 0 1 3-3h3.293L7.646 2.354l.707-.707 2.848 2.847L8.354 7.34l-.707-.707L9.28 5H6a2.002 2.002 0 0 0-2 2z"
                                fill="#001A72"/>
                            <path fill="none" d="M0 0h24v24H0z"/>
                        </svg>
                    </div>
                </div>
            </main>
        )

    }
}