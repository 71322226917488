import React, {useEffect, useState} from 'react';
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import {logout} from "../utils/auth";

import './Header.scss';

import logo from "../assets/img/neo-quimica-vista.svg";

export default function Header() {
    const [isClicked, setMenuIsClicked] = useState(false);
    const [fixedScroll, setFixedScroll] = useState(false);
    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        if (location.pathname.includes("/relatorio")) {
            document.body.classList.add('report-body');
        } else {
            document.body.classList.remove('report-body');
        }
    }, [location]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    function handleScroll() {
        let scroll = document.body.getBoundingClientRect().top;
        let scrollTest = (scroll < -50);
        setFixedScroll(scrollTest);
    }

    function toggleMenu() {
        if (isClicked) {
            document.body.classList.remove('menu-open');
        } else {
            document.body.classList.add('menu-open');
        }
        setMenuIsClicked(!isClicked);
    }

    function clickMenu() {
        document.body.classList.remove('menu-open');
        setMenuIsClicked(false);
    }

    return (
        <header id="header" className={`header ${(fixedScroll) ? "headerScroll" : ""}`}>

            <div className="ctHeader">
                <div className="container">
                    <div className="col-12 ctMenu">
                        <div className={`logo ${(isClicked) ? "showLogo" : ""}`} onClick={clickMenu}>
                            <Link to="/dashboard">
                                <img src={logo} alt="Neo Química Vista"/>
                            </Link>
                        </div>

                        <button id="btn-hamburger" onClick={toggleMenu}
                                className={`hamburger hamburger--spring ${(isClicked) ? 'is-active' : ''}`}
                                type="button">
                            <span className="hamburger-box"><span className="hamburger-inner"/></span>
                        </button>
                    </div>
                </div>
            </div>

            <nav className={`menu ${(isClicked) ? "menuOpen" : ""} `}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ul>
                                <li>
                                    <NavLink to="/dashboard" onClick={clickMenu}>Meus Relatórios</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/meus-dados" onClick={clickMenu}>Editar Perfil</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/faq" onClick={clickMenu}>FAQ</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contato" onClick={clickMenu}>Contato</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/termos-de-uso" onClick={clickMenu}>Termos de Uso</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/aviso-de-privacidade" onClick={clickMenu}>Aviso de
                                        Privacidade</NavLink>
                                </li>
                                <li>
                                    <button
                                        onClick={() => {
                                            clickMenu();
                                            logout();
                                            navigate("/");
                                        }}
                                    >Sair
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

        </header>
    )
}