import React, {useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

import api from "../../utils/api";
import {getDecodedToken, logout} from "../../utils/auth";

import './Sidebar.scss';

import imgProfile from "../../assets/img/ico-user.svg";
import icoExit from "../../assets/img/ico-exit.png";

function Sidebar({reports, loadMinimized}) {

  const [data, setData] = useState([]);
  const [minimized, setMinimized] = useState(false);
  const DECODED_TOKEN = getDecodedToken();
  const USER_IMG = sessionStorage.getItem("user-img");
  const navigate = useNavigate();

  useEffect(() => {
    getData();
    if (loadMinimized) {
      setMinimized(true);
      document.getElementById("template-dashboard").classList.toggle("full");
    }
  }, []);

  function handleMinimize() {
    setMinimized(true);
    document.getElementById("template-dashboard").classList.toggle("full");
  }

  function handleMaximize() {
    setMinimized(false);
    document.getElementById("template-dashboard").classList.toggle("full");
  }

  async function getData() {
    try {
      const response = await api.post(`/reports/list-by-user-with-card`, {user_id: DECODED_TOKEN.data.id});
      setData(response.data);
    } catch (err) {
      toast.error("Erro ao obter as informações.");
    }
  }

  return (
      <>
        {minimized &&
            <button
                onClick={handleMaximize}
                className="btn-maximize">
              +
            </button>
        }
        <aside className={`sidebar ${minimized ? "minimized" : ""}`}>
          <header>
            {reports &&
                <button
                    onClick={handleMinimize}
                    className="btn-minimize"/>
            }
            <div className="profile-pic"
                 onClick={() => {
                   if (minimized && window.innerWidth > 990) {
                     setMinimized(false);
                     document.getElementById("template-dashboard").classList.remove("full");
                   } else {
                     navigate("/meus-dados");
                   }
                 }}
                 style={{backgroundImage: `url(${USER_IMG ? USER_IMG : (DECODED_TOKEN && DECODED_TOKEN.data.file_url ? DECODED_TOKEN.data.file_url : imgProfile)})`}}
                 id="profile-user-pic"/>
            <p>Bem vindo (a)</p>
            <h2>{DECODED_TOKEN && DECODED_TOKEN.data && DECODED_TOKEN.data.name}</h2>
            {/*<p>The Goodfellas</p>*/}
          </header>

          {reports &&
              <nav>
                <header>
                  Meus Relatórios
                </header>
                {data.map((report, index) =>
                    <NavLink key={index} to={`/relatorio/${report.report_id}`}>{report.name}</NavLink>
                )}
              </nav>
          }

          <footer>
            <button
                onClick={() => {
                  navigate("/");
                  logout();
                }}
                className="btn-exit">
              <img src={icoExit} alt="Sair"/>
              <span>Sair</span>
            </button>
            <ul className="social">
              <li>
                <a href="https://www.facebook.com/neoquimicaoficial/" rel="noreferrer noopener" target="_blank">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.374257" y="0.374257" width="27.0988" height="27.0988" rx="13.5494" stroke="#001A72"
                          strokeWidth="0.748514"/>
                    <path
                        d="M16.5945 13.6483H14.7957V19.8094H12.1337V13.6483H10.8672V11.4729H12.1337V10.0589C12.1337 9.04886 12.6338 7.47168 14.8199 7.47168L16.7881 7.47945V9.59271H15.3603C15.1264 9.59271 14.7957 9.70148 14.7957 10.1832V11.4651H16.8204L16.5945 13.6483Z"
                        fill="#001A72"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/neoquimica/" rel="noreferrer noopener" target="_blank">
                  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19.572 9.0049C19.0638 8.3212 18.4023 7.92496 17.4989 7.93273C16.3534 7.94827 15.2079 7.9405 14.0625 7.9405C12.8605 7.9405 11.6586 7.93273 10.4567 7.9405C9.78712 7.94827 9.20632 8.18135 8.73846 8.63197C8.14959 9.19136 7.88338 9.90615 7.87532 10.6831C7.85918 12.8818 7.86725 15.0805 7.86725 17.2715C7.86725 17.3803 7.88338 17.489 7.89951 17.5978C8.02051 18.3359 8.37545 18.9341 9.06112 19.3226C9.56125 19.6101 10.1179 19.7344 10.6987 19.7344C12.9573 19.7421 15.2241 19.7344 17.4827 19.7344C17.5634 19.7344 17.6441 19.7344 17.7247 19.7266C19.0961 19.5479 20.1286 18.4291 20.1286 17.1006C20.1367 14.9718 20.1286 12.843 20.1286 10.7142C20.1286 10.1004 19.9511 9.51768 19.572 9.0049ZM13.9979 16.0595C12.6105 16.0672 11.4166 14.9795 11.4246 13.581C11.4246 12.2059 12.5701 11.1104 13.9899 11.1104C15.4177 11.1104 16.5712 12.2136 16.5712 13.5888C16.5712 14.9407 15.4177 16.0517 13.9979 16.0595ZM18.4346 10.4267C18.4265 10.753 18.2168 10.9783 17.878 10.9939C17.5876 11.0094 17.2972 11.0094 16.9987 10.9939C16.6841 10.9783 16.4583 10.753 16.4421 10.4422C16.4341 10.1625 16.4341 9.89061 16.4421 9.61091C16.4583 9.3079 16.7003 9.08259 17.0149 9.07482C17.2972 9.06706 17.5795 9.06706 17.8619 9.07482C18.1926 9.08259 18.4265 9.31567 18.4346 9.63422C18.4346 9.7663 18.4346 9.89838 18.4346 10.0382C18.4346 10.1625 18.4346 10.2946 18.4346 10.4267Z"
                        fill="#001A72"/>
                    <rect x="0.7683" y="0.374257" width="27.0988" height="27.0988" rx="13.5494" stroke="#001A72"
                          strokeWidth="0.748514"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://www.neoquimica.com.br/" rel="noreferrer noopener" target="_blank">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.482655" y="0.374257" width="27.0988" height="27.0988" rx="13.5494" stroke="#001A72"
                          strokeWidth="0.748514"/>
                    <path
                        d="M14.032 6.11279C12.4872 6.11279 10.9771 6.57089 9.69256 7.42916C8.40808 8.28742 7.40694 9.50731 6.81576 10.9346C6.22458 12.3618 6.0699 13.9323 6.37128 15.4475C6.67266 16.9626 7.41657 18.3544 8.50894 19.4467C9.6013 20.5391 10.9931 21.283 12.5082 21.5844C14.0234 21.8858 15.5939 21.7311 17.0211 21.1399C18.4484 20.5487 19.6682 19.5476 20.5265 18.2631C21.3848 16.9786 21.8429 15.4685 21.8429 13.9236C21.8429 11.8521 21.0199 9.86535 19.5551 8.40054C18.0903 6.93572 16.1036 6.11279 14.032 6.11279ZM19.5611 10.7115H18.2973C18.0256 9.84129 17.6103 9.02259 17.0686 8.2893C18.1057 8.8531 18.9679 9.69101 19.5611 10.7115ZM20.4387 13.9236C20.438 14.5358 20.3493 15.1446 20.1754 15.7315H18.6483C18.8461 14.5285 18.8461 13.3012 18.6483 12.0982H20.1754C20.351 12.6907 20.4397 13.3056 20.4387 13.9236ZM7.62539 13.9236C7.62609 13.3115 7.71476 12.7026 7.88868 12.1157H9.41574C9.21796 13.3188 9.21796 14.546 9.41574 15.7491H7.88868C7.71309 15.1565 7.6244 14.5416 7.62539 13.9236ZM10.6795 13.9236C10.6805 13.3168 10.7393 12.7114 10.855 12.1157H13.3299V15.7315H10.855C10.7393 15.1358 10.6805 14.5305 10.6795 13.9236ZM14.7341 7.93825C15.6789 8.6422 16.3986 9.60577 16.8053 10.7115H14.7341V7.93825ZM13.3299 7.93825V10.7115H11.2587C11.6655 9.60577 12.3851 8.6422 13.3299 7.93825ZM13.3299 17.1357V19.909C12.3851 19.2051 11.6655 18.2415 11.2587 17.1357H13.3299ZM14.7341 19.909V17.1357H16.8053C16.3986 18.2415 15.6789 19.2051 14.7341 19.909ZM14.7341 15.7315V12.1157H17.209C17.4381 13.316 17.4381 14.5488 17.209 15.7491L14.7341 15.7315ZM10.9955 8.2893C10.4538 9.02259 10.0385 9.84129 9.76679 10.7115H8.50301C9.09621 9.69101 9.95841 8.8531 10.9955 8.2893ZM8.50301 17.1357H9.76679C10.0385 18.006 10.4538 18.8247 10.9955 19.558C9.95841 18.9942 9.09621 18.1563 8.50301 17.1357ZM17.0686 19.558C17.6103 18.8247 18.0256 18.006 18.2973 17.1357H19.5611C18.9679 18.1563 18.1057 18.9942 17.0686 19.558Z"
                        fill="#001A72"/>
                  </svg>
                </a>
              </li>
            </ul>
          </footer>
        </aside>
      </>
  );
}

export default Sidebar;