import React, {useEffect} from 'react'
import {Helmet} from 'react-helmet-async';
import Sidebar from "../../components/Sidebar";

import './Contact.scss';

import ico from "../../assets/img/ico-contact.svg";
import icoWP from "../../assets/img/ico-contact-whatsapp.svg";
import icoTel from "../../assets/img/ico-contact-phone.svg";
import icoWWW from "../../assets/img/ico-contact-web.svg";

export default function Contact() {

  useEffect(() => {
    document.body.classList.add('logged');
    window.scrollTo(0, 0)
  });

  return (
    <main className="contact">

      <Helmet>
        <title>Neo Química Vista - Contato</title>
      </Helmet>

      <div className="container">
        <div className="template">
          <div className="ct-sidebar">
            <Sidebar/>
          </div>
          <section className="ct-content">
            <header className="header-inner">
              <h1>
                <img src={ico} alt="Contatos"/>
                <span>Contatos</span>
              </h1>
            </header>

            <div className="wrapper">
              <h2>Em casos de problemas com cadastro, dúvidas de acesso <br/>
                ou resgate deverá acionar a <strong>Central de Atendimento</strong>.
              </h2>

              <div className="ct-info">

                <div className="info">
                  <h3>Neo Química Clube <br/>
                    de Craques</h3>
                  <ul>
                    <li>
                      <a href="https://api.whatsapp.com/send?phone=551131812900" rel="noreferrer noopener"
                         target="_blank">
                        <img src={icoWP} alt="WhatsApp"/>
                        <p>
                          <strong>WhatsApp</strong>
                          (11) 3181 2900
                        </p>
                      </a>
                    </li>
                    <li>
                      <a href="tel:08005800072" rel="noreferrer noopener" target="_blank">
                        <img src={icoTel} alt="Central de Atendimento"/>
                        <p>
                          <strong>Central de Atendimento</strong>
                          0800 580 0072
                        </p>
                      </a>
                    </li>
                    <li>
                      <a href="https://neoquimicaclubedecraques.com.br" rel="noreferrer noopener" target="_blank">
                        <img src={icoWWW} alt="Site"/>
                        <p>
                          <strong>Site</strong>
                          neoquimicaclubedecraques.com.br
                        </p>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="info">
                  <h3>Neo Pharma <br/>
                    Excelência
                  </h3>
                  <ul>
                    <li>
                      <a href="https://api.whatsapp.com/send?phone=5541987981042" rel="noreferrer noopener"
                         target="_blank">
                        <img src={icoWP} alt="WhatsApp"/>
                        <p>
                          <strong>WhatsApp</strong>
                          (41) 98798 1042
                        </p>
                      </a>
                    </li>
                    <li>
                      <a href="tel:1142003120" rel="noreferrer noopener" target="_blank">
                        <img src={icoTel} alt="Central de Atendimento"/>
                        <p>
                          <strong>Central de Atendimento</strong>
                          (11) 4200 3120
                        </p>
                      </a>
                    </li>
                    <li>
                      <a href="https://portalneopharma.com.br" rel="noreferrer noopener" target="_blank">
                        <img src={icoWWW} alt="Site"/>
                        <p>
                          <strong>Site</strong>
                          portalneopharma.com.br
                        </p>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>

          </section>
        </div>
      </div>
    </main>
  )
}