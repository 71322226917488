import React, {useEffect, useState} from "react";
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {toast} from "react-toastify";
import * as Yup from 'yup';

import api from "../../utils/api";
import {getDecodedToken} from "../../utils/auth";
import Loading from "../Loading";

import './Form.scss';
import Upload from "../Upload";

function FormLogin() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const DECODED_TOKEN = getDecodedToken();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const response = await api.get(`/users/${DECODED_TOKEN.data.id}`);
      setLoading(false);
      setData(response.data);
    } catch (err) {
      toast.error("Erro ao obter as informações.");
    }
  }

  if (Object.keys(data).length) {
    return (
      <div className="form-profile">

        <Formik
          enableReinitialize={true}
          initialValues={{
            name: data.name ? data.name : "",
            email: data.email ? data.email : "",
            company: data.company ? data.company : "",
            position: data.position ? data.position : "",
            state: data.state ? data.state : "",
            regional: data.regional ? data.regional : "",
          }}
          validationSchema={
            Yup.object().shape({
              name: Yup.string().required('* Campo obrigatório.'),
              email: Yup.string().email('* Formato de e-mail inválido.')
                .required('* Campo obrigatório.'),
              company: Yup.string().required('* Campo obrigatório.'),
              position: Yup.string().required('* Campo obrigatório.'),
              state: Yup.string().required('* Campo obrigatório.'),
              regional: Yup.string().required('* Campo obrigatório.'),
            })
          }
          onSubmit={async (values, actions) => {
            setLoading(true);

            // Recaptcha.load(process.env.REACT_APP_RECAPTCHA_KEY).then(async (recaptcha) => {
            //   recaptcha.execute('USER_VALIDATE').then(async (token) => {

            const payload = {
              name: values.name,
              email: values.email,
              company: values.company,
              position: values.position,
              state: values.state,
              regional: values.regional
            };
            try {
              await api.put("/users/" + data.id + "/update-auth-user", payload);
              toast.success("Dados alterados com sucesso.");
            } catch (err) {
              toast.error("Erro ao enviar os dados, tente novamente.")
            } finally {
              setLoading(false);
            }

            //   }).catch((err) => {
            //     toast.error('Erro ao processar ReCaptcha. Tente novamente.');
            //     setLoading(false);
            //   });
            // }).catch((err) => {
            //   toast.error('Erro ao processar ReCaptcha. Tente novamente.');
            //   setLoading(false);
            // });

          }}>
          {({errors, touched, props}) => (
            <Form className="form-default light">
              <Loading showLoading={loading}/>
              <div className="row">
                <div className="col-12">
                  <div className={`field ${errors.name && touched.name ? 'error' : null}`}>
                    <label htmlFor="name">Nome</label>
                    <Field
                      name="name"
                      type="text"
                    />
                    <ErrorMessage name="name" component="span"/>
                  </div>
                </div>
                <div className="col-12">
                  <div className={`field ${errors.email && touched.email ? 'error' : null}`}>
                    <label htmlFor="email">E-mail</label>
                    <Field
                      name="email"
                      type="email"
                    />
                    <ErrorMessage name="email" component="span"/>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className={`field ${errors.company && touched.company ? 'error' : null}`}>
                    <label htmlFor="company">Empresa</label>
                    <Field
                      name="company"
                      type="text"
                    />
                    <ErrorMessage name="company" component="span"/>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className={`field ${errors.position && touched.position ? 'error' : null}`}>
                    <label htmlFor="position">Cargo</label>
                    <Field
                      name="position"
                      type="text"
                    />
                    <ErrorMessage name="position" component="span"/>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className={`field ct-select ${errors.state && touched.state ? 'error' : null}`}>
                    <label htmlFor="state">Estado</label>
                    <Field as="select" name="state">
                      <option value="">Selecione</option>
                      <option value="AC">Acre</option>
                      <option value="AL">Alagoas</option>
                      <option value="AP">Amapá</option>
                      <option value="AM">Amazonas</option>
                      <option value="BA">Bahia</option>
                      <option value="CE">Ceará</option>
                      <option value="DF">Distrito Federal</option>
                      <option value="ES">Espírito Santo</option>
                      <option value="GO">Goiás</option>
                      <option value="MA">Maranhão</option>
                      <option value="MT">Mato Grosso</option>
                      <option value="MS">Mato Grosso do Sul</option>
                      <option value="MG">Minas Gerais</option>
                      <option value="PA">Pará</option>
                      <option value="PB">Paraíba</option>
                      <option value="PR">Paraná</option>
                      <option value="PE">Pernambuco</option>
                      <option value="PI">Piauí</option>
                      <option value="RJ">Rio de Janeiro</option>
                      <option value="RN">Rio Grande do Norte</option>
                      <option value="RS">Rio Grande do Sul</option>
                      <option value="RO">Rondônia</option>
                      <option value="RR">Roraima</option>
                      <option value="SC">Santa Catarina</option>
                      <option value="SP">São Paulo</option>
                      <option value="SE">Sergipe</option>
                      <option value="TO">Tocantins</option>
                    </Field>
                    <ErrorMessage name="state" component="span"/>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className={`field ${errors.regional && touched.regional ? 'error' : null}`}>
                    <label htmlFor="regional">Regional</label>
                    <Field
                      name="regional"
                      type="text"
                    />
                    <ErrorMessage name="regional" component="span"/>
                  </div>
                </div>

                <div className="col-6 col-md-9"></div>
                <div className="col-6 col-md-3">
                  <button
                    type="submit"
                    className={`btn-default btn-submit ${loading ? "loading" : ""}`}
                    disabled={`${loading ? "disabled" : ""}`}
                  >
                    Salvar
                  </button>
                </div>

                <div className="col-12" style={{marginBottom: "20px"}}>
                  <h2>Imagem Perfil</h2>
                  <Upload/>
                </div>
              </div>
              <br/>
              <hr/>
            </Form>
          )}
        </Formik>

      </div>
    );
  } else {
    return null;
  }
}

export default FormLogin;