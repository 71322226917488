import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';

import {getDecodedToken} from "../../utils/auth";
import api from "../../utils/api";

import "./Upload.scss";
import {toast} from "react-toastify";
import Loading from "../Loading";

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


function Upload() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msgError, setMsgError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const DECODED_TOKEN = getDecodedToken();

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    onDrop: acceptedFiles => {
      setLoading(true);
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));

      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      api.put(`/users/${DECODED_TOKEN && DECODED_TOKEN.data.id}/upload`, formData, {
        onUploadProgress: progressEvent => {
          setUploadProgress(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        }
      }).then(response => {
        toast.success("Imagem de perfil atualizada com sucesso.");
        setLoading(false);
        getDataUser();
        setMsgError("");
      }).catch((error) => {
        setLoading(false);
        setMsgError(error && error.response && error.response.data && error.response.data.message);
      });
    }
  });

  async function getDataUser() {
    try {
      const response = await api.get(`/users/${DECODED_TOKEN.data.id}`);
      document.getElementById("profile-user-pic").style.backgroundImage = `url(${response && response.data && response.data.file_url})`;
      sessionStorage.setItem("user-img", response && response.data && response.data.file_url);
    } catch (err) {
      console.log("Não foi possível obter os dados do usuário após a atualização.");
    }
  }

  const thumbs = files.map(file => (
    <div className="thumb" key={file.name}>

      <Loading showLoading={loading}/>

      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="container-upload">
      <div className="row">
        <div className="col-12 col-md-6">
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <p>{(!loading) ? `Escolha um arquivo ou arraste aqui!` : `${uploadProgress}%`}</p>
          </div>
          {msgError && <p className="error-text">* {msgError}</p>}
        </div>
        <div className="col-12 col-md-6">
          <div className="thumbsContainer">
            {thumbs}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Upload;