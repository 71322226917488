import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Keyboard, Mousewheel, Navigation, Pagination} from "swiper";
import api from "../../utils/api";

import './Slider.scss';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

function Slider() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        try {
            const response = await api.get(`/banners/?order=DESC`);
            setLoading(false);
            setData(response.data);
        } catch (err) {
            console.log("Nenhum banner cadastrado.");
        }
    }

    if (data.length) {
        return (
            <div className="slider">
                <Swiper
                    style={{
                        "--swiper-pagination-bullet-inactive-color": "gray",
                        "--swiper-pagination-color": "#001A72",
                    }}
                    slidesPerView={1}
                    keyboard={{
                        enabled: true,
                    }}
                    autoplay={{
                        delay: 6000,
                        disableOnInteraction: false,
                    }}
                    mousewheel={true}
                    spaceBetween={0}
                    speed={1000}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Mousewheel, Keyboard, Pagination, Navigation, Autoplay]}
                    className="mySwiper">

                    {data.map((itemData, index) =>
                        <SwiperSlide key={index}>
                            <a
                                href={itemData.report_link}
                                target={(itemData.report_link.includes("http")) ? (!itemData.report_link.includes(`${window.location.origin}`)) ? "_blank" : "_self" : "_self"}
                            >
                                <img className="desktop" src={itemData.desktop_img} alt={`Imagem ${index}`}/>
                                <img className="mobile" src={itemData.mobile_img} alt={`Imagem ${index}`}/>
                            </a>
                        </SwiperSlide>
                    )}

                </Swiper>
            </div>
        );
    } else {
        return null;
    }
}

export default Slider;