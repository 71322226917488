import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {Link} from "react-router-dom";

import FormResetPassword from "../../components/Form/FormResetPassword";

import './Login.scss';

import logo from "../../assets/img/neo-quimica-vista.svg";

export default function ResetPassword() {

  useEffect(() => {
    document.body.classList.remove('logged');
    window.scrollTo(0, 0)
  });

  return (
    <main className="vista-login">

      <Helmet>
        <title>Neo Química Vista - Esqueci a senha</title>
      </Helmet>

      <section>
        <div className="company">
          <Link to={"/"}><img src={logo} alt="Neo Química Vista"/></Link>

          <h1>Exclusivo para <br/>
            distribuidores <br/>
            <strong>Neo Química</strong>
          </h1>
        </div>

        <div className="box-form">
          <FormResetPassword/>
        </div>
      </section>
    </main>
  )
}