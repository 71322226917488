import React, {useEffect, useRef} from 'react'
import {Helmet} from 'react-helmet-async';
import FaqComponent from "react-faq-component";
import {gsap, Power2} from "gsap";

import Sidebar from "../../components/Sidebar";

import './Faq.scss';

import ico from "../../assets/img/ico-faq.svg";

export default function Faq() {
  let containerFaq = useRef();

  useEffect(() => {
    gsap.timeline()
      .from(containerFaq.current, 0.5, {autoAlpha: 0, ease: Power2.easeInOut}, 0);
  }, []);

  useEffect(() => {
    document.body.classList.add('logged');
    window.scrollTo(0, 0)
  });

  const dataFaq = {
    rows: [
      {
        title: `Neo Pharma Excelência`,
        content: `
        <h2>1. O que é o programa Neo Pharma Excelência?</h2>
        <p>O programa é feito para desenvolver os PDVs de farmácias independentes e auxiliar seu crescimento além de proporcionar benefícios exclusivos, tendo o retorno em pontos para a compra que for realizada no distribuidor participante.</p>
        <h2>2. Quem pode participar do programa Neo Pharma Excelência?</h2>
        <p>Todos os PDVs que forem elegíveis, estarão no Painel PED.</p>
        <h2>3. O que é o PAINEL PED?</h2>
        <p>O painel PED contempla todos os PDVs que não são atendidos pela Hypera, o painel é exclusivo do distribuidor. Todo PDV PED é elegível ao programa.</p>
        <h2>4. Como consultar os elegíveis do programa?</h2>
        <p>Basta acessar o site abaixo, clicar em Cobertura – Relatórios – Base de PDVs Elegíveis</p>
        <p>Site: <a rel="noreferrer noopener" target="_blank" href="https://excelencia.portalneopharma.com.br/painel">https://excelencia.portalneopharma.com.br/painel</a></p>
        <h2>5. O Painel PED possui alteração?</h2>
        <p>Sim, o painel PED tem atualização mensal, está disponível no painel do Neo Pharma Excelência e também é enviada por e-mail.</p>
        <h2>6. Como é feito o cadastro?</h2>
        <p>O cadastrado da loja deve ser realizado no portal Neo Pharma com perfil “Parceiro Neo Pharma Excelência”. O cadastro deve ser realizado apenas com o CPF do Gestor da Loja. Caso um gestor tenha várias lojas, é possível cadastrar todos os CNPJs no mesmo CPF, desde que todos sejam elegíveis.</p>
        <p><strong>Confira o vídeo de como é feito o cadastro.</strong></p>
        <video width="640" height="360" controls poster="/videos/1-tutorial-como-cadastrar.png">
          <source src="/videos/1-tutorial-como-cadastrar.mp4" type="video/mp4" />
        </video>
        </p>
        <h2>7. Após cadastrado, o CNPJ será excluído se não for mais elegível?</h2>
        <p>Não, após o cadastro mesmo que o CNPJ não seja mais elegível em outro mês, ele continuará cadastrado no programa.</p>
        <h2>8. Como faço para acessar o site?</h2>
        <p>Para acesso basta entrar no site abaixo com o perfil “Parceiro Neo Pharma Excelência” e fazer login com CNPJ da loja e senha. <a rel="noreferrer noopener" target="_blank" href="https://www.portalneopharma.com.br/login">https://www.portalneopharma.com.br/login</a></p>
        <h2>9. Esqueci a senha, o que fazer?</h2>
        <p>Em caso de esquecimento de senha, basta clicar em “Esqueci a senha” e seguir os passos indicados. É possível ver o tutorial do vídeo abaixo ou contatar a central de atendimento.</p>
        <video width="640" height="360" controls poster="/videos/2-tutorial-troca-de-senha.png">
          <source src="/videos/2-tutorial-troca-de-senha.mp4" type="video/mp4" />
        </video>
        <h2>10. Cadastrei com Perfil errado, o que fazer?</h2>
        <p>Se o PDV cadastrou com perfil de Farmacêutico ou Balconista será necessário migrar o perfil. Basta seguir os passos no tutorial abaixo ou contatar a central de atendimento.</p>
        <video width="640" height="360" controls poster="/videos/4-tutorial-migracao-de-perfil.png">
          <source src="/videos/4-tutorial-migracao-de-perfil.mp4" type="video/mp4" />
        </video>
        <h2>11. Como pontuar?</h2>
        <p>Para pontuar no programa basta seguir as mecânicas mensalmente, realizando a compra no seu distribuidor participante da UF. Use a mecânica compre e ganhe e os aceleradores do mês para turbinar sua pontuação.</p>
        <h2>12. Quais são as mecânicas do programa?</h2>
        <p>Compre e Ganhe: A cada R$ 500,00 em compra, ganhe 10 pontos. Aceleradores do mês: Comprando os produtos aceleradores de cada mês, você ganha pontos extras.</p>
        <h2>13. Onde vejo quais são os aceleradores do mês?</h2>
        <p>Para ver quais são os aceleradores do mês acesse ao site para conferir em “Aceleradores”. <a rel="noreferrer noopener" target="_blank" href="https://www.portalneopharma.com.br/login">https://www.portalneopharma.com.br/login</a></p>
        <h2>14. Como resgatar?</h2>
        <p>O resgate deve ser feito dentro do site em “PREMIAÇÕES” e depois “Resgatar Prêmios”.</p>
        <p>Veja o vídeo com o passo a passo de como resgatar seus pontos. Em caso de dúvidas contatar a central de atendimento.</p>
        <video width="640" height="360" controls poster="/videos/3-tutorial-resgate-premios.png">
          <source src="/videos/3-tutorial-resgate-premios.mp4" type="video/mp4" />
        </video>
        <h2>15. Como saber quantos pontos o PDV tem disponível?</h2>
        <p>Para o PDV visualizar seus pontos basta acessar o site, clicar em “Resultados” e ao final da página “Resgatar premiações”. O ponto focal também pode acompanhar através do painel Neo Pharma Excelência em: <a rel="noreferrer noopener" target="_blank" href="https://excelencia.portalneopharma.com.br/painel">https://excelencia.portalneopharma.com.br/painel</a></p>
        <h2>16. Como é feita a apuração de pontos?</h2>
        <p>A apuração é feita após a validação do distribuidor com os dados de venda do mês anterior, que deverá ser validado com o Tradefy na contra- prova. Após a validação e apuração, a pontuação será creditada ao participante até o décimo dia útil do mês seguinte.</p>
        <h2>17. Validade dos pontos</h2>
        <p>Os pontos têm validade de 18 meses (1 ano e meio) considerando a partir da data de crédito de cada mês. Confira o vídeo explicativo.</p>
        <video width="640" height="360" controls poster="/videos/5-expiracao-de-pontos.png">
          <source src="/videos/5-expiracao-de-pontos.mp4" type="video/mp4" />
        </video>
        <h2>18. Como consultar os relatórios do Neo Pharma Excelência</h2>
        <p>Além dos relatórios disponíveis no Vista, você pode consultar através do link abaixo no painel do Neo Pharma Excelência quais PDVs são elegíveis, quais estão cadastrados e a pontuação de cada um. Link Painel: <a rel="noreferrer noopener" target="_blank" href="https://excelencia.portalneopharma.com.br/painel">https://excelencia.portalneopharma.com.br/painel</a></p>
        `,
      },
      {
        title: `Neo Química Clube de Craques`,
        content: `
        <h2>1. O que é o Neo Química Clube de Craques?</h2>
        <p>O NEO QUÍMICA CLUBE DE CRAQUES é um programa com Campanhas de vendas da Neo Química. Cada Campanha terá suas regras, produtos participantes e período elegível.</p>
        <h2>2. Quem pode participar da campanha?</h2>
        <p>São elegíveis a participar do programa a equipe de vendas dos Distribuidores elegíveis: vendedores (RCAs), supervisores e gerentes dos parceiros da Neo Química.</p>
        <h2>3. Como posso participar da campanha?</h2>
        <p>Para o cadastro, cada participante elegível receberá via e-mail um link de ativação. O participante deverá concordar com o regulamento do programa e cadastrar um número de telefone e senha. Somente a partir desse momento a navegação será liberada.</p>
        <h2>4. Não recebi o link de ativação de cadastro, o que fazer?</h2>
        <p>Solicite o link de ativação do participante em questão ao suporte via central de atendimento ou ao gerente Neo Química responsável pelo distribuidor.</p>
        <h2>5. Como faço para acessar a campanha?</h2>
        <p>Basta entrar no site da campanha https://neoquimicaclubedecraques.com.br/ informando seu login e senha.</p>
        <h2>6. Como faço para ser premiado na campanha?</h2>
        <p>Atente-se às regras no mural do site/app e siga as regras para ser elegível à premiação.</p>
        <h2>7. Quais são as premiações que posso resgatar?</h2>
        <p>Todos os participantes que atingirem a meta de acordo com os objetivos pré estabelecidos pela Neo Química em cada mecânica ganharão Neocoins considerando os critérios da campanha e perfil de usuário. Os Neocoins publicados na plataforma poderão ser trocados por premiações no catálogo de prêmios do site da campanha, composto por: Catálogos de produtos e Cartões Presente.</p>
        <h2>8. Como saberei quando e se ganhei pontos?</h2>
        <p>O carregamento dos Neocoins e todo o histórico poderá ser visualizado no site na área “EXTRATO”, após a validação final da campanha em questão.</p>
        <h2>9. Apuração divergente, o que fazer?</h2>
        <p>O ponto focal do distribuidor deve informar a Neo Química enviando o template de apuração padrão dentro do prazo de contestação de cada campanha.</p>
        <h2>10. Qual o prazo de contestação de resultados para uma campanha?</h2>
        <p>O prazo para contestação de resultados é de 5 dias úteis após a apuração final.</p>
        <h2>11.Minha pontuação não está disponível, como proceder?</h2>
        <p>Entre em contato conosco através do formulário do site no menu “Suporte” ou através da central de atendimento ou WhatsApp. Horário de atendimento: das 09h às12h e das 13h às 17h.</p>
        <h2>12. Posso repassar minha premiação para outra pessoa ou trocar por dinheiro?</h2>
        <p>As premiações em Neocoins são pessoais e intransferíveis, mas há possibilidade de resgatar o valor para uma conta bancária.</p>
        <h2>13. Em caso de desligamento, perco o direito a premiação?</h2>
        <p>Em caso de desligamento durante o período do programa o participante terá 30 dias, a partir da data da comunicação de dispensa para poder utilizar seus Neocoins no resgate de premiações. Após este período, seu acesso a Campanha será desabilitado e o saldo não transferido não poderá ser utilizado.</p>
        <h2>14. Onde receberei os prêmios resgatados?</h2>
        <p>As premiações serão entregues no endereço indicado no cadastro do participante registrado e confirmado no momento do resgate. Não é possível alterar o endereço de entrega do prêmio depois de resgatado. Se o premiado precisar efetuar atualizações no seu endereço estas deverão ser feitas antes do resgate. A responsabilidade pelo cadastramento de informações corretas é do participante, caso ocorra erro no endereço e nas informações pessoais, a organizadora não se responsabiliza por divergência na entrega.</p>
        <h2>15. Qual o prazo para entrega dos prêmios?</h2>
        <p>O prazo para entrega dos produtos e cartões físicos é de acordo com a data informada como “prazo de entrega” no momento do resgate. A entrega é realizada de segunda a sexta-feira e em horário comercial. Não há agendamentos ou entrega via caixa postal.</p>
        <p>Em locais onde há portarias residenciais ou comerciais, o prêmio é entregue ao porteiro/recepcionista ou responsável pelo local no momento da entrega. Ao receber o prêmio, o recebedor assina o protocolo de entrega com o número de documento.</p>
        <p>No caso de não localização do prêmio, o participante deve entrar em contato com o suporte da Campanha através da abertura de chamado no menu “Suporte” da plataforma, reportando a ocorrência e solicitando o protocolo de entrega com data e dados do recebedor do pedido dentro de 25 dias corridos da data do resgate.</p>
        <p>Para Cartão Virtual, o prazo para recebimento no e-mail cadastrado é de até 10 dias úteis. No caso do não recebimento dentro do prazo, ou abrir um chamado no menu “Suporte” da plataforma em até 30 dias da data de resgate.</p>
        <h2>16. Não recebi o produto resgatado dentro do prazo, o que faço?</h2>
        <p>Em caso do não recebimento do produto, excetos cartões presente, dentro do prazo de informado no momento do resgate, o premiado poderá solicitar o cancelamento do resgate e estornos dos Neocoins dentro do prazo de 25 dias a contar da data do resgate para reclamação. Caso o produto chegue após a solicitação de cancelamento, o premiado deverá recusar o recebimento do produto no ato da entrega para que o pedido seja cancelado e assim, a organizadora efetue a devolução dos Neocoins. A devolução dos Neocoins não será imediatamente após o premiado negar o recebimento, mas somente depois da confirmação do recebimento do prêmio no Centro de Distribuição do fornecedor.</p>
        <h2>17. Meu produto chegou com avaria/ defeito, o que faço?</h2>
        <ul>
        <li>Se a irregularidade for constatada no ato do recebimento, o participante deverá fazer a negativa de entrega anotando no verso da nota fiscal a irregularidade constatada e devolvendo o prêmio imediatamente para o entregador na embalagem original e com a nota fiscal. Recomenda-se tirar fotos da irregularidade para agilizar e facilitar a análise do ocorrido. Após esse procedimento, informar ao programa o ocorrido pela área “Suporte” da plataforma, anexando as fotos eventualmente tiradas, no prazo de até 2 dias úteis do recebimento do prêmio.</li>
        <li>Se a irregularidade for constatada depois da entrega, o participante deverá manter o produto sem uso dentro da embalagem original, guardar a nota fiscal e anotar o fato no recibo de entrega do prêmio, descrevendo a irregularidade constatada, preferencialmente, registrando com fotos a irregularidade constatada. Informar ao suporte da Campanha o ocorrido pela área “Suporte” da plataforma, no prazo de até 2 dias úteis do recebimento do prêmio. Feito isto, será agendada a data da coleta do prêmio ou orientada a postagem reversa pelo Correios. No ato da coleta, para sua segurança, o participante deverá exigir que o transportador lhe apresente a Nota Fiscal de Coleta, onde constará a descrição do item a ser coletado (exceção feita aos Correios, que emitem o código de objeto de coleta, composto por 13 dígitos).</li>
        </ul>
        <h2>18. Mudei de endereço para entrega dos produtos, como realizar a atualização no meu cadastro?</h2>
        <p>A alteração de endereço para entrega deve ser realizada antes do resgate. Acesse a página “Meus Endereços” dentro no menu “Minha Conta” na plataforma, onde é possível atualizar um endereço já cadastrado ou cadastrar um novo endereço.</p>
        <p>Caso tenha alterado o endereço de entrega após a realização do resgate, será necessário aguardar a devolução do prêmio ao estoque do parceiro. Somente após essa devolução podemos atualizar o endereço e solicitar uma nova tentativa de entrega. Para resgate de cartões presente não é possível realizar o cancelamento/estorno.</p>
        <h2>19. É possível realizar cancelamento de pedidos?</h2>
        <p>Após conclusão do resgate não é possível seguir com o cancelamento do pedido.</p>
        <h2>20. Não encontrei a resposta para a minha dúvida, como devo proceder?</h2>
        <p>Entre em contato conosco através do formulário “Suporte” em nosso site ou através da central de atendimento ou WhatsApp. Horário de atendimento: das 09h às12h e das 13h às 17h.</p>
        `,
      }
    ]
  };

  const styles = {
    rowContentPaddingTop: '30px',
    rowContentPaddingBottom: '30px',
    rowContentPaddingLeft: '30px',
    rowContentPaddingRight: '30px',
  };

  return (
    <main className="faq">

      <Helmet>
        <title>Neo Química Vista - Perguntas Frequentes</title>
      </Helmet>

      <div className="container">
        <div className="template">
          <div className="ct-sidebar">
            <Sidebar/>
          </div>
          <section className="ct-content">
            <header className="header-inner">
              <h1>
                <img src={ico} alt="Perguntas Frequentes"/>
                <span>Perguntas Frequentes</span>
              </h1>
            </header>

            <div className="wrapper">
              <FaqComponent
                styles={styles}
                data={dataFaq}
              />
            </div>

          </section>
        </div>
      </div>
    </main>
  )
}