import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";

import Header from "./components/Header";
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Faq from './pages/Faq';
import ForgotPassword from "./pages/Login/ForgotPassword";
import ResetPassword from "./pages/Login/ResetPassword";
import Profile from "./pages/Profile";
import Report from "./pages/Dashboard/Report";
import Contact from "./pages/Contact";
import Sso from "./pages/Login/Sso";
import Terms from "./pages/Legal/Terms";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";

import './assets/scss/main.scss';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <div className="app">
            <Router>
                <Header/>

                <Routes>
                    <Route exact path="/" element={<Login/>}/>
                    <Route exact path="/login" element={<Login/>}/>
                    <Route exact path="/esqueci-a-senha" element={<ForgotPassword/>}/>
                    <Route exact path="/reset/:token" element={<ResetPassword/>}/>
                    <Route exact path="/sso" element={<Sso/>}/>
                    <Route exact path="/dashboard" element={<Dashboard/>}/>
                    <Route exact path="/meus-dados" element={<Profile/>}/>
                    <Route exact path="/relatorio/:dataset_id" element={<Report/>}/>
                    <Route exact path="/faq" element={<Faq/>}/>
                    <Route exact path="/contato" element={<Contact/>}/>
                    <Route exact path="/termos-de-uso" element={<Terms/>}/>
                    <Route exact path="/aviso-de-privacidade" element={<PrivacyPolicy/>}/>
                </Routes>
            </Router>

            <ToastContainer position={toast.POSITION.BOTTOM_RIGHT}/>
        </div>
    );
}

export default App;
