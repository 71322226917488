import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {NavLink, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

import {getDecodedToken, logout} from "../../utils/auth";
import Sidebar from "../../components/Sidebar";
import Slider from "../../components/Slider";

import './Dashboard.scss';

import ico from "../../assets/img/ico-reports.svg";
import api from "../../utils/api";
import Loading from "../../components/Loading";

export default function Dashboard() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const DECODED_TOKEN = getDecodedToken();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add('logged');
    window.scrollTo(0, 0)
    getData();
  }, []);

  async function getData() {
    try {
      const response = await api.post(`/reports/list-by-user-with-card`, {user_id: DECODED_TOKEN.data.id});
      setLoading(false);
      setData(response.data);
    } catch (err) {
      toast.error("Erro ao obter as informações.");
      logout();
      navigate("/");
    }
  }

  return (
    <main className="dashboard">

      <Helmet>
        <title>Neo Química Vista - Dashboard</title>
      </Helmet>

      <Loading showLoading={loading}/>

      <div className="container">
        <div className="template">
          <div className="ct-sidebar">
            <Sidebar/>
          </div>

          <section className="ct-content">
            <Slider/>

            <header className="header-inner">
              <h1>
                <img src={ico} alt="Relatórios"/>
                <span>Meus Relatórios</span>
              </h1>
            </header>

            <div className="reports">
              {data.map((report, index) =>
                <NavLink key={index} to={`/relatorio/${report.report_id}`}>
                  <img src={report.file_url} alt={report.name} loading="lazy"/>
                </NavLink>
              )}
            </div>
          </section>
        </div>
      </div>
    </main>
  )
}