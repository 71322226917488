import React, {useState} from "react";
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import * as Recaptcha from "recaptcha-v3";
import * as Yup from 'yup';

import Loading from "../Loading";
import api from "../../utils/api";

import './Form.scss';

function FormResetPassword({handleClose}) {
    const [seePassword, setSeePassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    let {token} = useParams();

    return (
        <div className="login">
            <h3>Crie uma nova senha</h3>

            <Formik
                initialValues={{
                    passwordReset: "",
                    passwordResetConfirmation: ""
                }}
                validationSchema={
                    Yup.object().shape({
                        passwordReset: Yup.string().required('* Crie uma senha.')
                            .min(5, '* A senha deve conter mais de 6 caracteres.')
                            .required('* Campo obrigatório.'),
                        passwordResetConfirmation: Yup.string()
                            .oneOf([Yup.ref('passwordReset'), null], 'Senha digitada não corresponde.')
                            .required('* Campo obrigatório.')
                    })
                }
                onSubmit={async (values, actions) => {
                    setLoading(true);
                    Recaptcha.load(process.env.REACT_APP_RECAPTCHA_KEY).then(async (recaptcha) => {
                        recaptcha.execute('USER_RESET_PASSWORD').then(async (tokenRecaptcha) => {

                            const headers = {'Recaptcha': tokenRecaptcha}

                            const payload = {
                                password: values.passwordReset,
                                password_confirmation: values.passwordResetConfirmation
                            };
                            try {
                                const response = await api.put(`/users/recover-password/${token}`, payload, {headers: headers});
                                toast.success("Senha resetada com sucesso.");
                                navigate("/");
                            } catch (err) {
                                toast.error((err.response.data && err.response.data.errors && err.response.data.errors.message) ? err.response.data.errors.message : "Erro ao resetar a senha.");
                            } finally {
                                setLoading(false);
                            }

                        }).catch((err) => {
                            toast.error('Erro ao processar ReCaptcha. Tente novamente.');
                            setLoading(false);
                        });
                    }).catch((err) => {
                        toast.error('Erro ao processar ReCaptcha. Tente novamente.');
                        setLoading(false);
                    });
                }}>
                {({errors, touched, props}) => (
                    <Form className="form-default form-users">
                        <Loading showLoading={loading}/>

                        <div className={`field ${errors.passwordReset && touched.passwordReset ? 'error' : null}`}>
                            <label htmlFor="password">Senha</label>
                            <Field
                                id="passwordReset"
                                name="passwordReset"
                                type={seePassword ? "text" : "password"}
                            />
                            <ErrorMessage name="passwordReset" component="span"/>
                            <button
                                type="button"
                                className="btn-password"
                                onClick={() => setSeePassword(!seePassword)}>
                                {seePassword ?
                                    <svg width="27" height="12" viewBox="0 0 27 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <ellipse cx="13.0897" cy="6.05639" rx="3.77358" ry="3.7478" fill="#FFCD00"/>
                                        <path
                                            d="M0.907227 6.32796C4.65189 2.39431 14.8201 -3.11279 25.5356 6.32796C15.5114 15.2537 4.93994 10.047 0.907227 6.32796Z"
                                            stroke="#FFCD00" strokeWidth="0.724364"/>
                                    </svg> :
                                    <svg width="27" height="16" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 26.99 16.4"
                                         style={{transform: 'translateY(-1px)'}}>
                                        <defs>
                                            <clipPath id="clip-path" transform="translate(0 0)">
                                                <rect className="cls-1" width="27" height="16.4"/>
                                            </clipPath>
                                        </defs>
                                        <g>
                                            <g>
                                                <g className="cls-2" clipPath="url(#clip-path)">
                                                    <g>
                                                        <path className="cls-3"
                                                              d="M11.35,11.52a4,4,0,0,0,5.46-5.46l-5.45,5.45Z"
                                                              transform="translate(0 0)" fill="#FFCD00"/>
                                                        <path className="cls-3" d="M15.68,4.82a4,4,0,0,0-5.57,5.58Z"
                                                              transform="translate(0 0)"
                                                              fill="#FFCD00"/>
                                                        <path className="cls-3" d="M21.69.45,21.24,0,5.29,16l.45.45Z"
                                                              transform="translate(0 0)"
                                                              fill="#FFCD00"/>
                                                        <path
                                                            d="M8.12,12.38A20.34,20.34,0,0,1,.91,8.33,17.66,17.66,0,0,1,17,3.53L17.51,3A18.28,18.28,0,0,0,.22,8.13L0,8.36l.24.22a20.63,20.63,0,0,0,7.38,4.3l.5-.5Z"
                                                            transform="translate(0 0)" fill="#FFCD00"/>
                                                        <path
                                                            d="M26.73,8.11a22.92,22.92,0,0,0-7.41-4.56l-.5.5A22.32,22.32,0,0,1,26,8.35c-5.56,4.85-11.29,5.55-16,4.53l-.54.54a18.91,18.91,0,0,0,4.31.51,19.12,19.12,0,0,0,13-5.34L27,8.35l-.26-.23Z"
                                                            transform="translate(0 0)" fill="#FFCD00"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                }
                            </button>
                        </div>

                        <div
                            className={`field ${errors.passwordResetConfirmation && touched.passwordResetConfirmation ? 'error' : null}`}>
                            <label htmlFor="password">Confirmar senha</label>
                            <Field
                                name="passwordResetConfirmation"
                                type={seePassword ? "text" : "password"}
                            />
                            <ErrorMessage name="passwordResetConfirmation" component="span"/>
                            <button
                                type="button"
                                className="btn-password"
                                onClick={() => setSeePassword(!seePassword)}>
                                {seePassword ?
                                    <svg width="27" height="12" viewBox="0 0 27 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <ellipse cx="13.0897" cy="6.05639" rx="3.77358" ry="3.7478" fill="#FFCD00"/>
                                        <path
                                            d="M0.907227 6.32796C4.65189 2.39431 14.8201 -3.11279 25.5356 6.32796C15.5114 15.2537 4.93994 10.047 0.907227 6.32796Z"
                                            stroke="#FFCD00" strokeWidth="0.724364"/>
                                    </svg> :
                                    <svg width="27" height="16" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 26.99 16.4"
                                         style={{transform: 'translateY(-1px)'}}>
                                        <defs>
                                            <clipPath id="clip-path" transform="translate(0 0)">
                                                <rect className="cls-1" width="27" height="16.4"/>
                                            </clipPath>
                                        </defs>
                                        <g>
                                            <g>
                                                <g className="cls-2" clipPath="url(#clip-path)">
                                                    <g>
                                                        <path className="cls-3"
                                                              d="M11.35,11.52a4,4,0,0,0,5.46-5.46l-5.45,5.45Z"
                                                              transform="translate(0 0)" fill="#FFCD00"/>
                                                        <path className="cls-3" d="M15.68,4.82a4,4,0,0,0-5.57,5.58Z"
                                                              transform="translate(0 0)"
                                                              fill="#FFCD00"/>
                                                        <path className="cls-3" d="M21.69.45,21.24,0,5.29,16l.45.45Z"
                                                              transform="translate(0 0)"
                                                              fill="#FFCD00"/>
                                                        <path
                                                            d="M8.12,12.38A20.34,20.34,0,0,1,.91,8.33,17.66,17.66,0,0,1,17,3.53L17.51,3A18.28,18.28,0,0,0,.22,8.13L0,8.36l.24.22a20.63,20.63,0,0,0,7.38,4.3l.5-.5Z"
                                                            transform="translate(0 0)" fill="#FFCD00"/>
                                                        <path
                                                            d="M26.73,8.11a22.92,22.92,0,0,0-7.41-4.56l-.5.5A22.32,22.32,0,0,1,26,8.35c-5.56,4.85-11.29,5.55-16,4.53l-.54.54a18.91,18.91,0,0,0,4.31.51,19.12,19.12,0,0,0,13-5.34L27,8.35l-.26-.23Z"
                                                            transform="translate(0 0)" fill="#FFCD00"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                }
                            </button>
                        </div>

                        <button
                            type="submit"
                            className={`btn-default btn-submit ${loading ? "loading" : ""}`}
                            disabled={`${loading ? "disabled" : ""}`}
                        >
                            Resetar senha
                        </button>

                    </Form>
                )}
            </Formik>

        </div>
    );
}

export default FormResetPassword;