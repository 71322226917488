import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

import './Login.scss';

import logo from "../../assets/img/neo-quimica-vista.svg";
import Loading from "../../components/Loading";
import {login} from "../../utils/auth";

export default function Sso() {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.remove('logged');
    window.scrollTo(0, 0)
    let searchParams = new URLSearchParams(window.location.search);
    let tokenURL = searchParams.get("token");
    const token = tokenURL;
    const base64Url = token.split('.')[1];
    if (base64Url){
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(atob(base64));
      if (typeof payload === 'object') {
        login(tokenURL);
        setLoading(false);
        navigate("/dashboard");
      } else {
        setLoading(false);
        toast.error('Dados inválidos favor efetuar o login.');
        navigate("/");
      }
    } else {
      setLoading(false);
      toast.error('Dados inválidos favor efetuar o login.');
      navigate("/");
    }

  });

  return (
    <main className="vista-login">

      <Helmet>
        <title>Neo Química Vista - Esqueci a senha</title>
      </Helmet>

      <Loading showLoading={loading}/>

      <section>
        <div className="company">
          <Link to={"/"}><img src={logo} alt="Neo Química Vista"/></Link>

          <h1>Exclusivo para <br/>
            distribuidores <br/>
            <strong>Neo Química</strong>
          </h1>
        </div>

        <div className="box-form">
          Autenticando...
        </div>
      </section>
    </main>
  )
}