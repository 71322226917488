import React from 'react';
import ReactDOM from "react-dom";

import './Loading.scss';

import icoLoading from "./ico-loading.svg";

const Loading = ({showLoading}) => {
  return ReactDOM.createPortal(
    <div id="general-loading" className={`${showLoading ? "show" : ""}`}>
      <img src={icoLoading} alt="Carregando"/>
    </div>,
    document.querySelector("#loading")
  )
}

export default Loading;